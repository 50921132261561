import { JsonDocument } from '@shared/data-access/common';

export class JDocument implements JsonDocument {
  html: string;
  documentType: number;

  constructor(html: string, documentType: number) {
    this.html = html;
    this.documentType = documentType;
  }
}
